import { ROUTES } from "@/shared/constants/routes";
import { useRouter } from "next/router";
import { useEffect } from "react";

function IndexPage() {
  const router = useRouter();

  useEffect(() => {
    router.push(ROUTES.PROMO);
  }, []);
}

export default IndexPage;
